exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-recommendations-js": () => import("./../../../src/pages/book-recommendations.js" /* webpackChunkName: "component---src-pages-book-recommendations-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category.template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2009-08-17-classroom-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2009-08-17-classroom/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2009-08-17-classroom-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-08-06-3rd-cisco-contest-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-08-06-3rd-cisco-contest/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-08-06-3rd-cisco-contest-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-08-20-iftar-party-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-08-20-iftar-party/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-08-20-iftar-party-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-09-27-sum-is-6-first-die-is-4-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-09-27-sum-is-6-first-die-is-4/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-09-27-sum-is-6-first-die-is-4-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-10-10-digital-day-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-10-10-digital-day/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-10-10-digital-day-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-10-24-installing-lamp-server-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-10-24-installing-lamp-server/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-10-24-installing-lamp-server-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2010-11-15-avro-in-ubuntu-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2010-11-15-avro-in-ubuntu/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2010-11-15-avro-in-ubuntu-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2011-01-08-left-recursion-elimination-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2011-01-08-left-recursion-elimination/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2011-01-08-left-recursion-elimination-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2011-01-08-lexical-analyzer-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2011-01-08-lexical-analyzer/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2011-01-08-lexical-analyzer-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2011-11-26-displaying-errors-in-lamp-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2011-11-26-displaying-errors-in-lamp/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2011-11-26-displaying-errors-in-lamp-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2012-03-04-bill-management-system-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2012-03-04-bill-management-system/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2012-03-04-bill-management-system-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2012-10-15-last-programming-contest-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2012-10-15-last-programming-contest/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2012-10-15-last-programming-contest-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-01-19-beginning-requirejs-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-01-19-beginning-requirejs/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-01-19-beginning-requirejs-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-01-31-code-technologies-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-01-31-code-technologies/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-01-31-code-technologies-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-06-25-ihwbd-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-06-25-ihwbd/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-06-25-ihwbd-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-08-04-beginning-indexeddb-with-idbwrapper-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-08-04-beginning-indexeddb-with-idbwrapper/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-08-04-beginning-indexeddb-with-idbwrapper-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-08-13-iims-ci-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-08-13-iims-ci/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-08-13-iims-ci-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2013-11-05-introduction-to-codeigniter-template-library-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2013-11-05-introduction-to-codeigniter-template-library/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2013-11-05-introduction-to-codeigniter-template-library-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2014-03-26-bookarena-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2014-03-26-bookarena/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2014-03-26-bookarena-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2014-08-21-introduction-to-gulp-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2014-08-21-introduction-to-gulp/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2014-08-21-introduction-to-gulp-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2016-10-21-introduction-to-webpack-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2016-10-21-introduction-to-webpack/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2016-10-21-introduction-to-webpack-index-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2023-05-07-authentication-with-oidc-index-mdx": () => import("./../../../src/templates/post.template.js?__contentFilePath=/opt/buildhome/repo/src/posts/2023-05-07-authentication-with-oidc/index.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2023-05-07-authentication-with-oidc-index-mdx" */),
  "component---src-templates-posts-template-js": () => import("./../../../src/templates/posts.template.js" /* webpackChunkName: "component---src-templates-posts-template-js" */)
}

