module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.14.0_gatsby-plugin-sharp@5.14.0_gatsby@5.14.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"showCaptions":true,"markdownCaptions":true,"quality":100,"maxWidth":900,"linkImagesToOriginal":true,"backgroundColor":"white","withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_graphql@16.10.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Shibbir Ahmed","short_name":"Gatsby","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca3a365d088da2188438e6ca77e7402c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-omni-font-loader@2.0.2_gatsby@5.14.1_react-helmet@6.1.0/node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.7.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
